import React from "react";
import { Navbar } from "../components/Navbar";
import { Marquee } from "../components/Marquee";
import { Carousel } from "../components/Carousel";
import { Helmet } from "react-helmet";


import insauce from "../artimages/insauce.jpg";
import julie from "../artimages/julie.jpg";
import abbatia from "../artimages/abbatia.jpg";
import oxisticker from "../artimages/oxisticker.jpg"
import fluoride from "../artimages/fluoride.png"
import comms from "../artimages/commissionsheetfin.jpg"
import tampa from "../artimages/chafa.jpg"
import pardon from "../artimages/pardon.jpg"

export function ScrapsPage() {
    const imageUrls = [
        `${oxisticker}`,
        `${fluoride}`,
        `${julie}`,
        `${comms}`,
        `${tampa}`
    ]

    return (
        <div className="mx-md-5 mx-auto row d-md-flex flex-row">
            <Helmet>
                <meta name="description"
                    content="Scraps by KHADAVER" />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Mirror Man, Cappystrip, Cappy, Strip, Beanie, Elf, Devil, Orange, Cap, Studios" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <Marquee />
            <Navbar />
            <Carousel
                images={imageUrls}
            />
        </div>
    )
}