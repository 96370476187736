import React from "react";
import { NavLink, Link } from "react-router-dom";
import khadaver from "../images/khadaveranim.gif"
import hamburger from "../images/hamburger.png"
import follow from "../images/follow.png"
import about from "../images/about.png"
import shop from "../images/shop.png"
import contact from "../images/contact.png"
import cappy from "../images/cappy.png"
import deadman from "../images/deadman.png"
import projects from "../images/projects.png"
import ovo from "../images/ovo.png"
import blog from "../images/blog.png"
import scraps from "../images/scraps.png"
import insta from "../images/insta.png"

export function Navbar() {

    function navContents() {
        return (
            <div className="d-flex flex-column mt-4 mt-md-0" >
                <a rel="noreferrer" target="_blank" href="https://www.instagram.com/khdvrdotnet/" className={(navData) => (navData.isActive ? "h5 mb-4 ps-1" : 'h5 mb-4 ps-1')}>
                    <img src={follow} alt="follow" style={{ width: '110px' }}></img>
                </a>
                <div className="navbar-toggler h5 mb-3 px-2" data-bs-toggle="collapse" data-bs-target="#comic-collapse" aria-expanded="false" aria-label="Toggle art">
                    <img src={projects} alt="projects" style={{ width: '135px' }}></img>
                </div>
                <div className="collapse " id="comic-collapse">
                    <div className="d-flex flex-column ps-2">
                        <NavLink to="/cappy" className={(navData) => (navData.isActive ? "active-nav ps-1" : 'ps-1')}>
                            <img src={cappy} alt="cappy strip" style={{ width: '80px' }}></img>
                        </NavLink>
                    </div>
                </div>
                <NavLink to="/scraps" className={(navData) => (navData.isActive ? "active-nav h5 mb-3 ps-1" : 'h5 mb-3 ps-1')}>
                    <img src={scraps} alt="scraps" style={{ width: '110px' }}></img>
                </NavLink>
                <NavLink to="/blog" className={(navData) => (navData.isActive ? "active-nav h5 mb-3 ps-1" : 'h5 mb-3 ps-1')}>
                    <img src={blog} alt="blog" style={{ width: '70px' }}></img>
                </NavLink>
                <a rel="noreferrer" target="_blank" href="http://shop.khadaver.net/" className="h5 mb-3 ps-1">
                    <img src={shop} alt="shop" style={{ width: '80px' }}></img>
                </a>
                <NavLink to="/about" className={(navData) => (navData.isActive ? "active-nav h5 mb-3 ps-1" : 'h5 mb-3 ps-1')}>
                    <img src={about} alt="about" style={{ width: '95px' }}></img>
                </NavLink>
                <NavLink to="/contact" className={(navData) => (navData.isActive ? "active-nav h5 mb-3 ps-1" : 'h5 mb-3 ps-1')}>
                    <img src={contact} alt="contact" style={{ width: '130px' }}></img>
                </NavLink>
            </div>
        )
    }

    return (
        <nav className=" col-md-4 mt-md-5 mt-3">
            <div className="d-flex justify-content-between mx-auto">
                <img src={khadaver} alt="khadaver logo" id="khadaver" className="mb-md-4 w-75"></img>
                <div className="navbar-toggler p-0 d-md-none" id="hamburger" data-bs-toggle="collapse" data-bs-target="#nav-links-sm" aria-expanded="false" aria-label="Toggle navigation">
                    <img src={hamburger} alt="hamburger icon" className="w-50"></img>
                </div>
            </div>
            <ul className="collapse d-md-block" id="nav-links">
                {navContents()}
            </ul>
            <ul className="collapse d-md-none" id="nav-links-sm">
                {navContents()}
            </ul>
        </nav>
    )
}